module.exports = [
        {
            title:'部门管理',
            role_id:'100000',
            child:[
                {
                    title:'修改部门名称',
                    role_id:'1000001',
                    flex:true
                },
                {
                    title:'添加子部门',
                    role_id:'1000002',
                    flex:true
                },
                {
                    title:'删除部门',
                    role_id:'1000003',
                    flex:true
                }
            ]
        },
        {
            title:'员工管理',
            role_id:'100012',
            child:[
                {
                    title:'新增',
                    role_id:'1000004',
                    flex:true
                },
                {
                    title:'导出',
                    role_id:'1000005',
                    flex:true
                },
                {
                    title:'导入员工信息',
                    role_id:'1000006',
                    flex:true
                },
                {
                    title:'编辑',
                    role_id:'1000007',
                    flex:true
                },
                {
                    title:'重置密码',
                    role_id:'1000008',
                    flex:true
                },
                {
                    title:'删除',
                    role_id:'1000009',
                    flex:true
                }
            ]
        },
        {
            title:'职位管理',
            role_id:'100010',
            child:[
                {
                    title:'新增',
                    role_id:'1000011',
                    flex:true
                },
               
            ]
        },
]
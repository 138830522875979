<template>
	<div class="employee publicBox">
		<navBar :list="navlist" name="职位管理"></navBar>
		<div class="content-main">
		<el-form inline>
			<el-form-item>
						<my-input placeholder="输入工号" v-model="searchForm.managementName" Imodel="searchForm.user_id">
						<template #pre>工号</template>
					</my-input>
				<!-- <my-input v-model="searchForm.managementName" placeholder="输入职位名称"></my-input> -->
			</el-form-item>
			<el-form-item>
				<myButton @click="search">
				<span>搜索</span>
				<template #preImage>
					<img src="../assets/search.png" alt="">
				</template>
				</myButton>
			</el-form-item>
			<el-form-item>
				<myButton title="新增" @click="editAdd()">
				<template #preImage>
					<img src="../assets/zj.png" alt="">
				</template>
				</myButton>
				<!-- <el-button type="primary" @click="editAdd()">新增</el-button> -->
			</el-form-item>
			<!-- <el-form-item label="">
				<myselect v-model="test" placeholder="请选择" :options="{value:'id',label:'name'}" :list="testlist"></myselect>
			</el-form-item> -->
		</el-form>

		<div class="table-box">
			<el-table :data="tableData" :cell-style="{'text-align':'center','height':'48px'}" :header-cell-style="{'text-align':'center','background':'rgb(245, 245, 245)','height':'48px'}">
				<el-table-column type="index" width="100px" label="序号"></el-table-column>
				<el-table-column label="职位" prop="managementName"></el-table-column>
				<el-table-column label="现有人数" prop="userTotal"></el-table-column>
				<el-table-column label="操作">
					<template v-slot="scope">
					<div class="popbox">
						<pop tips="编辑" popRight>
							<img class="popIcon" @click="editAdd(scope.row)" src="../assets/img/edit.png" />
						</pop>
						<pop tips="删除">
							<img class="popIcon"  @click="deleted(scope.row)" src="../assets/img/deleted.png" />
						</pop>
					</div>
						<!-- <el-button type="text" @click="editAdd(scope.row)">编辑</el-button>
						<el-button type="text" @click="deleted(scope.row)" style="color: red;">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- <div class="publicPage">
			<el-pagination :total="total" @current-change="turnPage" :page-size="searchForm.pageSize"></el-pagination>
		</div> -->
			<div class="public-page">
				<el-pagination
					:total="total"
					next-text="下一页"
					prev-text="上一页"
					@current-change="turnPage"
					layout="total,prev,pager,next"
				></el-pagination>
			</div>

		<el-dialog v-model="vasible" :title="addtype == 'add' ? '添加' : '编辑'" @close="clear">
			<el-form
				label-width="120px"
				label-position="right"
				ref="editForms"
				:model="editForm"
				:rules="rules"
			>
				<el-form-item label="职位名称" prop="managementName">
					<el-input class="public-input" v-model="editForm.managementName" placeholder="输入职位名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-tree
						:data="permissionList"
						@check-change="nodeClick"
						node-key="role_id"
						ref="tree"
						show-checkbox
						:render-content="renderContent"
						:render-after-expand="false"
						:props="{ label: 'title', children: 'child' }"
					></el-tree>
				</el-form-item>
			</el-form>
			<template #footer>
				<myButton title="取消" @click="vasible = false"></myButton>
				<myButton title="确认" style="margin-left:15px" type="primary" @click="submit"></myButton>
				<!-- <el-button @click="vasible = false">取消</el-button> -->
				<!-- <el-button type="primary" @click="submit">确认</el-button> -->
			</template>
		</el-dialog>
		</div>
	</div>
</template>
<script setup>
import {
	ref,
	reactive,
	inject,
	nextTick,
	getCurrentInstance
} from 'vue';
import permissionLists from '@/assets/permis.js'
let vm = inject('plugin');
let navlist = []
let vasible = ref(false);
let instance = getCurrentInstance()
// 列表--------------------------------
let total = ref(0);
let tableData = ref([]);
async function getPosList() {
	let res = await vm.HttpGet('/admin/management/list', searchForm);
	tableData.value = res.data.list;
	total.value = res.data.total;
}

let searchForm = reactive({
	managementName: '',
	page: 1,
	pageSize: 10
})
function search(){
	vm.debounce(turnPage)
}
function turnPage(e = 1) {
	searchForm.page = e;
	getPosList()
}
getPosList()

// ---------------添加编辑----------------------
let editForms = ref(null)
let editForm = reactive({
	managementName: '',
	role_worker_id: [],
	role_worker_names: []
})
let rules = {
	managementName: [
		{ required: true, message: '请输入职位名称', trigger: 'blur' }
	],
	role_worker_id: [
		{ required: true, message: '请选择权限', trigger: 'blur' }
	]
}
let permissionList = reactive(permissionLists)
function submit() {
	let url = '';
	addtype.value == 'add' ? url = '/admin/management/create' : url = '/admin/management/update'
	editForms.value.validate(valid => {
		if (valid) {
			vm.HttpPost(url, editForm)
				.then(res => {
					vm.message(res.msg);
					vasible.value = false;
					getPosList();

				})
		}
	})
}
let tree = ref(null)
function nodeClick() {
	let data = tree.value.getCheckedNodes();
	editForm.role_worker_id = []
	editForm.role_worker_names = [];
	data.length > 0 ?
		data.forEach(item => {
			editForm.role_worker_id.push(item.role_id);
			editForm.role_worker_names.push(item.title)
		})
		: (function () {
			editForm.role_worker_id = []
			editForm.role_worker_names = [];
		}());
}


// --------------------------------------------------------
let addtype = ref('add')
function editAdd(item) {
	if (item) {
		editForm.managementName = item.managementName;
		editForm.role_worker_id = JSON.parse(item.roleWorkerId);
		editForm.management_id = item.managementId
		addtype.value = 'edit';

	} else {
		addtype.value = 'add';
	}

	vasible.value = true;
	nextTick(() => {
		let { refs } = instance;
		refs.tree.setCheckedKeys(editForm.role_worker_id)
	changeCss()

	})

}

// 排列
function renderContent(h, { data }) {
	let className = '';
	if (data.flex) {
		className = 'flex'
	} else {
		className = ''
	}
	return h(
		'span',
		{
			class: className
		},
		data.title
	)
}
// 最后一行横向排列
function changeCss() {
	nextTick(() => {
		let fles = document.querySelectorAll('.flex');
		fles.forEach(item => {
			// item.parentNode.parentNode.style.display = 'inline-flex'
			item.parentNode.parentNode.style.width = '500px'
			item.parentNode.style.cssFloat = 'left'
			item.parentNode.style.styleFloat = 'left'

		})
	})
}
function deleted(item) {
	vm.confirm('确认删除该职位?')
		.then(() => {
			vm.HttpPost('/admin/management/deleteByIds', { ids: [item.managementId] })
				.then(res => {
					vm.message(res.msg);
					getPosList()
				})
		})
}

// 清空验证
function clear() {
	editForms.value.resetFields();
	editForm.managementName = '';
	editForm.role_worker_id = [];
	editForm.management_id = ''

}
</script>
<style lang="scss">
</style>
